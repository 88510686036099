/* You can add global styles to this file, and also import other style files */
@import "../node_modules/bootstrap/scss/bootstrap-grid.scss";
@import "../node_modules/bootstrap/scss/bootstrap-utilities.scss";
body, html {
  margin: 0;
  padding: 0;
  background-color: var(--white);
  width: 100%;
  height: 100%;

}
